<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addNewDocument()"
                                  icon="plus"
                                  color="primary">{{$ml.get('buttons.ADD_DOCUMENT')}}
                    </button-field>
                </div>
            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="documents"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list">

                        <template #cell(attributes.language)="data">
                            <span>{{data.item.attributes.language | langLabel}}</span>
                        </template>

                        <template #cell(attributes.type)="data">
                            <span>{{data.item.attributes.type | docTypeLabel}}</span>
                        </template>

                        <template #cell(file)="data">
                            <a :href="data.item.attributes.attachment.url()" target="_blank">{{trimFileName(data.item.attributes.attachment.name())}}</a>
                        </template>

                        <template #cell(actions)="data">
                            <div class="text-right">
                                <button-field @click="editDocument(data.item)"
                                              circle
                                              outline
                                              v-tooltip="$ml.get('tooltips.EDIT_DOCUMENT')"
                                              icon="edit"
                                              class="table-action-btn"
                                              color="primary">
                                </button-field>
                                <button-field @click="deleteDocument(data.item)"
                                              circle
                                              outline
                                              v-tooltip="$ml.get('tooltips.DELETE')"
                                              icon="trash"
                                              class="table-action-btn"
                                              color="error"
                                >
                                </button-field>
                            </div>

                        </template>

                    </b-table>

                </div>
            </div>
        </div>
    </div>



</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            loading: false,
            emptyHtml: '<div class="text-center"><font-awesome-icon icon="user" size="3x" color="gray" /><p class="mt-2">No data found</p></div>',
            tableColumns: [
                { key: 'attributes.name', label: this.$ml.get('NAME'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.type', label: this.$ml.get('TYPE'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.language', label: this.$ml.get('LANGUAGE'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.revision', label: this.$ml.get('tables.headers.revision'), thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'file', label: this.$ml.get('FILE_NAME'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                { key: 'attributes.description', label: this.$ml.get('DESCRIPTION'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                {
                    key: 'actions',
                    label: this.$ml.get('ACTIONS'),
                    thClass: 'font-bebas-neue text-center font-small-4',
                    sortable: false,
                    stickyColumn: true
                }
            ]
        }
    },
    created() {
        this.loadDocuments();
    },
    computed: {
        documents() {
            return this.$store.state.protectedDocuments;
        }
    },
    methods: {
        addNewDocument() {
            this.$store.commit('setModalData', {id: null});
            this.$store.commit('toggleDashboardModal', {name: 'ProtectedDoc_Form', data: {id: null}})
        },
        deleteDocument(document) {
            if (confirm('Are you sure you want to delete this document?')) {
                this.$store.dispatch('deleteDocument', document).then(() => {
                    this.loadDocuments();
                }).catch((error) => {
                    console.error('Error while deleting document', error);
                });
            }
        },

        editDocument(document) {
            this.$store.commit('setModalData', document);
            this.$store.commit('toggleDashboardModal', {name: 'ProtectedDoc_Form', data: {id: document.id}})
        },

        trimFileName(fileName) {
            // Split the filename by underscore
            let copy = fileName.slice();
            const parts = copy.split('_');

            // If there's more than one part, return everything after the first underscore
            // Otherwise, return the original filename
            return parts.length > 1 ? parts.slice(1).join('_') : copy;
        },
        loadDocuments(){
            this.loading = true;
            this.$store.dispatch('getAvailableDocuments').then(() => {
                this.loading = false;
            }).catch((error) => {
                console.error('Error while loading documents', error);
                this.loading = false;
            });
        },
    }
}

</script>

<style scoped>

</style>
